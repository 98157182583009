import React from "react";
import Separator from "../../../components/Separator";

export const SectionSpacer = ({ children, fullWidth }) => {
  return (
    <>
      {children.map((section, idx) => (
        <React.Fragment key={idx}>
          {idx > 0 && <Separator fullWidth={fullWidth} />}
          <div className="rn-section-gap">{section}</div>
        </React.Fragment>
      ))}
    </>
  );
};
