import Header from "./header/Header";
import FooterTwo from "./footer/Footer";
import * as React from "react";

interface LayoutProps {
  children: React.ReactNode;
  hideContactBanner?: boolean;
}
const Layout = ({ children, hideContactBanner }: LayoutProps) => {
  return (
    <>
      <main className="page-wrapper">
        <Header />
        {children}
        <FooterTwo hideContactBanner={hideContactBanner} />
      </main>
    </>
  );
};

export default Layout;
