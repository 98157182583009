import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import SplitContentImage from "../components/SplitContentImage";
import React from "react";
import { SectionSpacer } from "../feature/blog/layout/SectionSpacer";
import { graphql, Link } from "gatsby";
import { FeatureList } from "../components/FeatureList";
import { GatsbyImage } from "gatsby-plugin-image";

const About = ({ data }) => {
  console.log(data);
  return (
    <>
      <SEO title="About HueBox" />
      <Layout>
        <BreadcrumbBanner
          title="About HueBox"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="About HueBox"
        />
        <div className="main-content">
          <SectionSpacer>
            <SplitContentImage
              title="HueBox is an immersive experience that lights up your brand"
              image={
                <GatsbyImage
                  image={data.immersiveImage.childImageSharp.gatsbyImageData}
                />
              }
            >
              <p>
                HueBox is the worlds most dynamic programmable LED lightbox
                system. Capturing attention by coming to life through light and
                sound to reveal a hidden side, a new dimension or highlight of a
                product feature, driving drama and intrigue to immerse your
                customers in your brand or product.
              </p>
            </SplitContentImage>

            <SplitContentImage
              title="More than just a box"
              image={
                <GatsbyImage
                  image={data.watchingImage.childImageSharp.gatsbyImageData}
                />
              }
              reverse
            >
              <FeatureList
                items={[
                  {
                    title: "Seriously bright",
                    content: `Bright enough to compete with daylight and exhibition lights, without blinding your customers.`,
                  },
                  {
                    title: "Animated lights",
                    content: `Highlight your brand with our dynamic animations - see our videos to experience yourself.`,
                  },
                  {
                    title: "Dynamic Colours",
                    content: `Available options include full RGB lighting to really make it pop.`,
                  },
                ]}
              />
            </SplitContentImage>

            <SplitContentImage
              title="Modular System"
              image={
                <GatsbyImage
                  image={data.systemImage.childImageSharp.gatsbyImageData}
                />
              }
            >
              <FeatureList
                items={[
                  {
                    title: "Any size",
                    content: `Available in 0.5 meter increments up to 5m x 10m`,
                  },
                  {
                    title: "Fabric Printed Graphics",
                    content: `Printed using the widest colour gamut HP Latex printers`,
                  },
                  {
                    title: "Swappable Graphics",
                    content: `Easily change graphics or animations at any time - no tools required`,
                  },
                ]}
              />
            </SplitContentImage>

            <SplitContentImage
              image={
                <GatsbyImage
                  image={data.featuresImage.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                />
              }
              reverse
            >
              <FeatureList
                items={[
                  {
                    title: "Flexible design for any environment",
                    content: `Huebox modular LED Lightboxes can be easily designed and
                programmed for any environment from Airports to Exhibitions.`,
                  },
                  {
                    title: "Stand out from the crowd",
                    content: `Create a visually stunning display that puts your competitors in
                the shade.`,
                  },
                  {
                    title: "Easy installation",
                    content: `Huebox is a modular system that is easily installed.`,
                  },
                ]}
              />
            </SplitContentImage>
            <div className="container">
              <h2>Find out more</h2>
              {data.allSanityAbout.nodes.map(({ title, id, handle }) => (
                <>
                  <Link
                    key={id}
                    className="btn-default"
                    to={`/about/${handle.current}`}
                  >
                    {title}
                  </Link>{" "}
                </>
              ))}
            </div>
          </SectionSpacer>
        </div>
      </Layout>
    </>
  );
};
export default About;
export const query = graphql`
  {
    allSanityAbout {
      nodes {
        id
        handle {
          current
        }
        title
      }
    }
    immersiveImage: file(
      relativePath: {
        eq: "Hue_Box_LEDs_ON_Colour_Test_Gradient_Material_LONG_Material_OFF.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    systemImage: file(
      relativePath: {
        eq: "Hue_Box_LEDs_ON_Material_OFF_Cross_Section_Close.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    featuresImage: file(relativePath: { eq: "christmas-dog-house.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    watchingImage: file(relativePath: { eq: "man-watching-lights.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`;
